import {Container} from "react-bootstrap";
import '@styles/_trackListing.scss'
import {useLoaderData, useNavigate} from 'react-router-dom';
import ListTable from "@components/ListTable";
import React, {useMemo} from "react";
import {IPublicSingleAlbumResponse} from '../interfaces/ApiResponses';
import ItemControls from "@components/player/itemControls";

const AlbumView = () => {
  const navigate = useNavigate();
  const playlist = useLoaderData() as IPublicSingleAlbumResponse;

  const dateYear = useMemo(() => {
    // If there is no release date, return an empty string.
    if (!playlist.data.releaseDate) return '';
    // Otherwise, get the year from the Date object.
    const date = new Date(playlist.data.releaseDate);
    return date.getFullYear();
  }, [playlist.data.releaseDate]);

    //Set albumId
    const albumId = playlist.data.id;

  return (
    <div className="m-tracklisting">
      <Container fluid className={'py-4 py-lg-5 listings'}>
          <button onClick={() => navigate(-1)} className="btn-link e-back"><i className="bi bi-chevron-left"></i> Back</button>
          <div className="e-top">
            <div className="image">
              <img src={playlist.data.image} alt=""/>

                <div className="overlay">
                    <ItemControls itemId={albumId}  itemType='album' />
                </div>
            </div>
            <div className="text">
              <h1>{playlist.data.title}</h1>
              <p>
                  {playlist.data.artist.join(", ")}
                <br/>
                {dateYear}
              </p>
            </div>
          </div>

        <ListTable tracks={playlist.data.tracks} />
      </Container>
    </div>
  )
}

export default AlbumView;