import React, { useState } from "react";
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import Logo from '@assets/logo.png';
import spotify from "@assets/iconSpotify.png";
import {useLogout, useMe} from '../utils/api';

const Header = () => {
  const {data, status} = useMe();
  const logout = useLogout();

  const [navActive, setNavActive] = useState(false);
  const navToggle = () => {
    setNavActive(!navActive);
  };

  const [modalActive, setModalActive] = useState(false);
  function modalToggle(e: { preventDefault: () => void; }){
    e.preventDefault();
    setModalActive(!modalActive);
  }

  return (
    <div>
      <Navbar variant={'dark'} bg="primary"  expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to={'/'} className={'extrabold'} href="#home">
            <img alt={'Logo'} src={Logo} width={76}/>
            <span>
              streambeats.app
            </span>
          </Navbar.Brand>
          <Navbar.Collapse>
            <Nav>
              <Nav.Link as={NavLink} to={'/'} className={'extrabold text-white text-lowercase'} onClick={navToggle}>Genres</Nav.Link>
              <Nav.Link as={NavLink} to={'/new'} className={'extrabold text-white text-lowercase'} onClick={navToggle}>New Releases</Nav.Link>
            </Nav>
            <button onClick={(e) => {
              // Don't do anything unless our API call is complete
              if (status === 'success') {
                // If we are logged in, simply log out instead.
                logout.mutate();
              } else if (status === 'error') {
                // Errors = bad status code = not logged in
                modalToggle(e);
              }
            }} className={`btn-link extrabold text-white text-lowercase text-decoration-none nav-link login-link ${data?.data ? 'hidden' : ''}`}>
              {status === 'loading' ? <i className={'spinner-border spinner-border-sm'} /> : ('Login')}
            </button>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <div className={modalActive ? 'modalcustom active' : 'modalcustom'}>
        <div className="background" onClick={modalToggle} />
        <div className='modal-popup'>
          <div className='modal-title'>
            <h3>Welcome to StreamBeats</h3>
          </div>
          <div className="modal-body">
            <div className="login">
              <p>
               Login to your Spotify account to play music from <span>StreamBeats</span>.
              </p>
              <div className="options">
                <a href={`${process.env.REACT_APP_API_URL}/oauth/spotify`}>
                    <span className="icon">
                      <img src={spotify} alt="" width={46} height={46}/>
                    </span>
                  Login with Spotify
                </a>
              </div>
              <div className="smalltext">
                <p>By signing into StreamBeats, you agree to our <a href="/terms">Terms</a> and <a href="/privacy">Privacy Policy</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;