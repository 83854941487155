// import {Container} from 'react-bootstrap';
// import ListTable from '@components/ListTable';
import React from 'react';
import {observer} from 'mobx-react-lite';

const QueueView = observer(() => {
    return (
        <div className="m-tracklisting">
            {/*<Container fluid className={'py-4 py-lg-5 listings'}>*/}
            {/*    <div className="e-top">*/}
            {/*        <h1>Queue</h1>*/}
            {/*        {tracks.length === 0 ? ('') : (*/}
            {/*            <span className='e-clear' onClick={clearQueue}>Clear Queue</span>*/}
            {/*        )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        tracks.length === 0 ? (*/}
            {/*            <p>There are currently no tracks in the queue.</p>*/}
            {/*        ) : (*/}
            {/*            <ListTable tracks={tracks}/>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</Container>*/}
        </div>
    )
})

export default QueueView;