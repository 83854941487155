import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import Api from "./utils/api";
import ReactDOM from 'react-dom/client';
import '@styles/index.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import NewReleases from '@pages/NewReleases';
import Genres from '@pages/Genres';
import PlaylistView from "@pages/PlaylistView";
import AlbumView from "@pages/AlbumView";
import QueueView from '@pages/QueueView';
import ErrorBoundry from "@components/ErrorBoundry";


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundry />,
    children: [
      {
        path: '',
        element: <Genres />,
        errorElement: <ErrorBoundry />
      },
      {
        path: 'new',
        element: <NewReleases />,
        errorElement: <ErrorBoundry />
      },
      {
        path: 'playlist/:playlistId',
        loader: async ({ params }) => {
          return Api.getPlaylistId(params.playlistId)
        },
        element: <PlaylistView />,
        errorElement: <ErrorBoundry />
      },
      {
        path: 'album/:albumId',
        loader: async ({ params }) => {
          return Api.getAlbumId(params.albumId)
        },
        element: <AlbumView />,
        errorElement: <ErrorBoundry />
      },
      {
        path: 'queue',
        element: <QueueView />,
        errorElement: <ErrorBoundry />
      }
    ]
  }
])

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
    </QueryClientProvider>
  </React.StrictMode>
);

