import {Outlet} from 'react-router-dom';
import Header from '@components/Header';
import Player from '@components/player/Player';
import LogoSquare from "@assets/logo-square.png";
import React, {useEffect} from "react";
import Footer from "@components/Footer";
import ScrollToTop from "@components/ScrollToTop";
import {useMe} from './utils/api';
import BasePlayer from '@lib/BasePlayer';

const App = () => {
  const {data} = useMe();

  useEffect(() => {
    if (data?.data.platform === 'SPOTIFY') {
      BasePlayer.startPlaybackStateTask();
    }

    return () => {
      BasePlayer.stopPlaybackStateTask();
    }
  }, [data]);

  return (
    <div className='sb-app'>
      <div className='blocked'>
        <img alt={'Logo'} src={LogoSquare} width={76}/>
        Please expand the window to use StreamBeats
      </div>
      <Header />
      <Outlet />
      <Player />
      <Footer />
        <ScrollToTop />
    </div>
  )
}

export default App;
