import React from "react";
import {useLogout, useMe} from "../utils/api";

const Footer = () => {
  const {data, status} = useMe();
  const logout = useLogout();

  return (
    <footer>
      &copy; {new Date().getFullYear()} StreamBeats

      {/*Toggle Class of Visible if logged in*/}
      <span className={data?.data ? 'visible' : ''}>
        <button className={'btn-link'} onClick={() => {
          // Don't do anything unless our API call is complete
          if (status === 'success') {
            // If we are logged in, simply log out instead.
            logout.mutate();
          }
        }}>
          Logout
        </button>
      </span>
    </footer>
  )
}

export default Footer;