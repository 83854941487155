import React from "react";
import {useNavigate, useRouteError} from "react-router-dom";

const ErrorBoundry = () => {
    const navigate = useNavigate();
    let error = useRouteError();
    console.error(error);

    return (
        <div className='m-error d-flex flex-column justify-content-center py-4 text-center'>
            <div className="container">
                <h1>Oops, something went wrong</h1>
                <p>
                    There was a problem loading this page. Please try again.
                </p>
                <button className="btn btn-primary" onClick={() => navigate(0)}>Reload</button>
            </div>
        </div>
    )
}

export default ErrorBoundry;