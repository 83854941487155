import React, {useEffect, useRef, useState} from "react";
import '@styles/_player.scss'
import {observer} from 'mobx-react-lite';
import BasePlayer from '@lib/BasePlayer';
import {useMe} from "../../utils/api";
import useComponentVisible from "@components/VisibleComponent";

const Player = observer(() => {
    const {status} = useMe();

    let canInteract = true;
    if (status !== 'success' || BasePlayer.isInteractionBlocked) {
        canInteract = false;
    }

    //PlayHead Animation & Title Scrolling
    const [stateChange, setstateChange] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(BasePlayer.playbackState.currentTrack.duration);
    const [largeTitleWidth, setLargeTitleWidth] = useState(false);
    const titleRef = useRef<HTMLDivElement>(null);

    //Click Seeking functionality
    const trackRef = useRef<HTMLDivElement>(null);
    const [x, setX] = useState<number | undefined>();
    const [playHeadWidth, setPlayHeadWidth] = useState<number | undefined>();

    //**GENERAL CONTROLS**/
    const togglePlay = () => {
        BasePlayer.togglePlay().then(() => {
            getPosition();
        });
    }

    //Set positions for calculation of the location of the track header playback
    const getPosition = () => {
        setX(trackRef.current?.offsetLeft);
        setPlayHeadWidth(trackRef.current?.offsetWidth);
    };

    //Check for window resize event / Get location of playhead in reference to viewport / Set Window Width
    useEffect(() => {
        getPosition();

        function handleWindowResize() {
            //Set the title width if scroll is needed
            if (titleRef.current?.offsetWidth !== undefined) {
                if (titleRef.current.offsetWidth > window.innerWidth) {
                    setLargeTitleWidth(true);
                } else {
                    setLargeTitleWidth(false);

                }
            }
        }

        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('resize', getPosition);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.addEventListener('resize', getPosition);

            getPosition();
        };
    }, []);


    //Check to see if track has changed and check title width, Spotify spams a few different updates so we check it through the track duration property
    if (BasePlayer.playbackState.currentTrack.duration !== currentTrack) {
        //Toggle that the state has changed, save new duration
        setstateChange(true);
        setCurrentTrack(BasePlayer.playbackState.currentTrack.duration);


        //State hasn't changed after 1000 / Needs timeout for pause between tracks loading, 1000 seems to be the most accurate
        setTimeout(() => {
            setstateChange(false);

            //Set the title width if scroll is needed
            if (titleRef.current?.offsetWidth !== undefined) {
                if (titleRef.current.offsetWidth > window.innerWidth) {
                    setLargeTitleWidth(true);
                } else {
                    setLargeTitleWidth(false);

                }
            }
        }, 500);
    }


    //Seek Click & Drag Functionality
    let percentage: number;
    let seekPosition: number;
    let mouseDown = false;

    const updateSeek = (e: { clientX: any; }) => {
        if (x !== undefined && playHeadWidth !== undefined) {
            //Set percentage of where user clicked on tracker
            percentage = ((e.clientX - x) / playHeadWidth) * 100;
            document.documentElement.style.setProperty(
                '--currentPosition',
                percentage + '%'
            );

            //Set the current position of the track in ms
            seekPosition = Math.floor((percentage * BasePlayer.playbackState.currentTrack.duration) / 100);
        }
    }
    //Set Mouse down to true, for mouseevent to use drag
    const mouseDownToggle = () => {
        mouseDown = true;

        console.log(mouseDown)
    }
    const mouseUpToggle = (e: { clientX: any; }) => {
        if (mouseDown) {
            updateSeek(e);

            BasePlayer.seekTime(seekPosition).then(() => {
                BasePlayer.startPlaybackStateTask();
                mouseDown = false;
            });
        }
    }

    const mouseDrag = (e: { clientX: any; }) => {
        if (mouseDown) {
            BasePlayer.stopPlaybackStateTask().then(() => {
                updateSeek(e);
            });
        }
    }

    const mouseLeave = (e: { clientX: any; }) => {
        if (mouseDown) {
            updateSeek(e);

            BasePlayer.seekTime(seekPosition).then(() => {
                mouseDown = false;
            });
        }
    }

    // Device List
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const devicesAvailable = () => {
        BasePlayer.devices().then(() => {
            setIsComponentVisible(true);
        });
    }

    //**QUEUE BUTTON**//
    //Queue Context Menu
    // const [clicked, setClicked] = useState(false);
    //
    // //Hide Context Menu on Click Off
    // useEffect(() => {
    //     const handleClick = () => setClicked(false);
    //     window.addEventListener("click", handleClick)
    //     return () => {
    //         window.removeEventListener("click", handleClick);
    //     };
    // })
    // const contextMenu = (e: any) => {
    //     e.preventDefault();
    //     setClicked(true);
    // }

    // const clearQueue = () => {
    //     if (!BasePlayer.playbackState.paused) {
    //         // BasePlayer.togglePlay();
    //         // BasePlayer.seek(0);
    //     }
    // }

    return (
        <div className='player col-12'>
      <span className={`title ${largeTitleWidth ? 'scroll' : ''}`} ref={titleRef}>
        {
            BasePlayer.playbackState.currentTrack && (
                <>{BasePlayer.playbackState.currentTrack.name} {!BasePlayer.playbackState.currentTrack.artist ? '' : ' - '}
                    <span>{BasePlayer.playbackState.currentTrack.artist}</span></>
            )
        }
      </span>
            <div className="inner">
                <button disabled={!canInteract} className={'bi bi-skip-start-fill'}
                        onClick={() => BasePlayer.prevTrack()}/>
                <button disabled={!canInteract}
                        className={`bi bi-${BasePlayer.playbackState.paused ? 'play' : 'pause'}-fill`}
                        onClick={togglePlay}/>
                <button disabled={!canInteract} className={'bi bi-skip-end-fill'}
                        onClick={() => BasePlayer.skipTrack()}/>

                <div className={`status ${!canInteract ? 'disabled' : ''} ${!stateChange ? '' : 'stateupdate'}`}
                     onMouseDown={mouseDownToggle} onMouseUp={mouseUpToggle} onMouseMove={mouseDrag}
                     onMouseLeave={mouseLeave} ref={trackRef}>
                    <div className="current">
                        <div className="indicator"/>
                    </div>
                </div>

                {/*<div className='queuebutton' id='queueButton' onContextMenu={contextMenu}>*/}
                {/*    <button disabled={!canInteract} onClick={() => navigate('queue')} className="bi bi-list"/>*/}
                {/*    {clicked && (*/}
                {/*        <div className='e-contextmenu'>*/}
                {/*            <button onClick={clearQueue}>*/}
                {/*                Clear queue*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}


                <div className={`deviceButton ${!canInteract ? 'disabled' : ''}`}
                     ref={ref}
                >
                    <button onClick={devicesAvailable}>
                        <i className="bi bi-speaker"></i>
                    </button>
                    {isComponentVisible &&
                        <div className={`popup`}>
                            <ul>
                                {BasePlayer.deviceList.devices.map(
                                    item =>
                                        (!item.restricted &&
                                            <li id={item.id} className={item.active ? 'selected' : ''}
                                                onClick={() => BasePlayer.transferDevice(item.id).then(() => {
                                                    BasePlayer.devices().then(() => {
                                                        setIsComponentVisible(false);
                                                    })
                                                })}>
                                                {item.name}
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                    }
                </div>

            </div>
            {
                (status === 'success') ? <></> :
                    <p className={'player-help extrabold text-lowercase text-primary small'}>Not connected</p>
            }
        </div>
    )
})

export default Player;