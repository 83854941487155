import {Container} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import GenreItem from '@components/GenreItem';
import {usePlaylists} from '../utils/api';
import React, {useCallback} from 'react';

const Genres = () => {
  const {status, data} = usePlaylists();

  const renderPlaylists = useCallback(() => {
    const playlists: JSX.Element[] = [];

    console.log(playlists);

    if (data && data.data) {
      for (const i of data.data) {
        playlists.push(<GenreItem key={i.id} name={i.name} image={i.image} playlistId={i.id}/>)

      }
    }

    return playlists;
  }, [data]);

  return (
    <>
      <Helmet>
        <title>Genres | StreamBeats.app</title>
      </Helmet>
      <Container fluid className={'py-4 py-lg-5 listings'}>
        {
          status === 'loading' ? <div className={'spinner-border text-primary'} /> : (
            <div className="row">
              {renderPlaylists()}
            </div>
          )
        }
      </Container>
    </>
  )
}

export default Genres;