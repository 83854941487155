import {Container} from "react-bootstrap";
import '@styles/_trackListing.scss'
import {useLoaderData, useNavigate} from 'react-router-dom';
import ListTable from "@components/ListTable";
import React from "react";
import {IPublicSinglePlaylistResponse} from '../interfaces/ApiResponses';
import ItemControls from "@components/player/itemControls";

const PlaylistView = () => {
    const navigate = useNavigate();
    const playlist = useLoaderData() as IPublicSinglePlaylistResponse;
    const playlistId = playlist.data.id;

    return (
        <div className="m-tracklisting">
            <Container fluid className={'py-4 py-lg-5 listings'}>
                <button onClick={() => navigate(-1)} className="nav-link e-back"><i className="bi bi-chevron-left"></i> Back</button>
                <div className="e-top">
                    <div className="image">
                        <img src={playlist.data.image} alt={playlist.data.name}/>

                        <div className="overlay">
                            <ItemControls itemId={playlistId}  itemType='playlist' />
                        </div>
                    </div>
                    <div className="text">
                        <h1>{playlist.data.name}</h1>
                        <p>
                            {playlist.data.description}
                        </p>
                    </div>
                </div>

                <ListTable tracks={playlist.data.tracks}/>
            </Container>
        </div>
    )
}

export default PlaylistView;