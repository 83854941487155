import React, {useCallback} from "react";
import Api from "../../utils/api";

interface ItemProps {
    itemId: string;
    itemType: string;
}

const ItemControls: React.FC<ItemProps> = (props) => {
    const {itemId, itemType} = props;

    //Set Queue to Album and Play
    const doPlayList = useCallback(async () => {
        let itemList;

        // Fetch playlist details
        if (itemType === 'playlist') {
            itemList = await Api.getPlaylistId(itemId);
        } else if (itemType === 'album') {
            itemList = await Api.getAlbumId(itemId);
        }

        if (itemList !== undefined) {
            if (itemList.data) {
                await Api.spotifyPlay({uris: itemList.data.tracks.filter((t) => t.platforms.spotify !== undefined).map((t) => t.platforms.spotify) as string[]});
            }
        }

    }, [itemId, itemType])

    //Shuffle Queue and Play
    const doPlayShuffle = useCallback(async () => {
        let itemList;

        // Fetch playlist details
        if (itemType === 'playlist') {
            itemList = await Api.getPlaylistId(itemId);
        } else if (itemType === 'album') {
            itemList = await Api.getAlbumId(itemId);
        }

        if (itemList !== undefined) {
            if (itemList.data) {
                //Shuffle the tracks
                function shuffle(array:any) {
                    array.sort(() => Math.floor(Math.random() - 0.5));
                }
                shuffle(itemList.data.tracks);

                // Then, start playback.
                await Api.spotifyPlay({uris: itemList.data.tracks.filter((t) => t.platforms.spotify !== undefined).map((t) => t.platforms.spotify) as string[]});
            }
        }
    }, [itemId, itemType])

    return (
        <>
            <button onClick={() => doPlayList()}><i className="bi bi-play-fill"></i></button>
            <button onClick={() => doPlayShuffle()}><i className="bi bi-shuffle"></i></button>
        </>
    )
}

export default ItemControls;