import '@styles/_trackListing.scss'
import {ITrack} from '../interfaces/PlayerModels';
import React, {useEffect, useState, useRef, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import BasePlayer from "@lib/BasePlayer";
import Api from "../utils/api";

interface IProps {
    tracks: ITrack[];
}

const ListTable: React.FC<IProps> = observer((props) => {
    const {tracks} = props;
    // const currentTrack = BasePlayer.getQueue().currentTrack;
    let trackNumber = 0;

    const RenderRow: React.FC<ITrack> = (track) => {
        trackNumber++;

        // If this track is the one currently playing
        const isCurrentTrack = track.title === BasePlayer.playbackState.currentTrack.name;


        // Context Menu
        const [clicked, setClicked] = useState(false);
        const [points] = useState({
            x: 0,
            y: 0,
        });

        //Hide Context Menu on Click Off
        useEffect(() => {
            const handleClick = () => setClicked(false);
            window.addEventListener("click", handleClick)
            return () => {
                window.removeEventListener("click", handleClick);
            };
        })

        //Context Menu Location
        // const contextMenu = (e: any) => {
        //     e.preventDefault();
        //     const rowClicked = e.currentTarget.id;
        //     const activeRow = document.getElementById(rowClicked) as HTMLElement | null;
        //
        //     setClicked(true);
        //
        //     if (activeRow !== null) {
        //         setPoints({
        //             x: e.pageX - activeRow.offsetLeft,
        //             y: e.pageY - activeRow.offsetTop,
        //         });
        //     }
        // }

        //Clicked Outside Current Context
        const tableRef = useRef(null);

        function useOutsideAlerter(ref: any) {
            useEffect(() => {
                function handleClickOutside(event: any) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setClicked(false);
                    }
                }

                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }, [ref]);
        }

        useOutsideAlerter(tableRef);


        //Add to queue button click
        const addToQueue = () => {
            // BasePlayer.getQueue().addToQueue(track)
        }
        const trackClick = useCallback(async (e: any) => {
            let selectedPlaylist = [];

            // Double Click Event
            if (e.detail === 2) {

                //Get Index of track entry
                const clickedTrack = tracks.indexOf(track);
                const trackListCurrent = tracks.map((x) => x)
                let currentPlaylist = trackListCurrent.splice(clickedTrack, tracks.length);
                for (let t of currentPlaylist) {
                    selectedPlaylist.push(t);
                }

                BasePlayer.updateIsInteractionBlocked(true);
                await Api.spotifyPlay({uris: selectedPlaylist.filter((t) => t.platforms.spotify !== undefined).map((t) => t.platforms.spotify) as string[]}).then(() => {
                    BasePlayer.updateIsInteractionBlocked(false);
                    BasePlayer.startPlaybackStateTask();
                });
            }
        }, [track])

        const playClick = useCallback(async (e: any) => {
            let selectedPlaylist = [];

            //Get Index of track entry
            const clickedTrack = tracks.indexOf(track);
            const trackListCurrent = tracks.map((x) => x)
            let currentPlaylist = trackListCurrent.splice(clickedTrack, tracks.length);
            for (let t of currentPlaylist) {
                selectedPlaylist.push(t);
            }

            BasePlayer.updateIsInteractionBlocked(true);
            await Api.spotifyPlay({uris: selectedPlaylist.filter((t) => t.platforms.spotify !== undefined).map((t) => t.platforms.spotify) as string[]}).then(() => {
                BasePlayer.updateIsInteractionBlocked(false);
                BasePlayer.startPlaybackStateTask();
            });

        }, [track])


        return (
            <div
                //
                id={track.id}
                // onContextMenu={contextMenu}
                ref={tableRef}
                onClick={trackClick}
                className={`table-row ${isCurrentTrack ? 'isCurrentTrack' : ''}`}
            >
                <div className="table-cell number" onClick={playClick}>
                    <div className={`eq ${BasePlayer.playbackState.paused ? 'paused' : 'playing'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect className="eq-bar eq-bar--1" x="4" y="4" width="3.7" height="8"/>
                            <rect className="eq-bar eq-bar--2" x="10.2" y="4" width="3.7" height="16"/>
                            <rect className="eq-bar eq-bar--3" x="16.3" y="4" width="3.7" height="11"/>
                        </svg>
                    </div>

                    <div className="play">
                        <i className="bi bi-play-fill"></i>
                    </div>

                    <span>
                        {trackNumber}
                    </span>
                </div>
                <div className="table-cell song">{track.title}</div>
                <div className="table-cell artist">{track.artist.join(", ")}</div>
                <div className="table-cell album"></div>

                {clicked && (
                    <div
                        className='e-contextmenu'
                        style={{
                            'top': points.y,
                            'left': points.x,
                        }}
                    >
                        <button onClick={addToQueue}>
                            Add to queue
                        </button>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="e-details">
            <div className="e-table">
                <div className="table-head">
                    <div className="table-row">
                        <div className="table-cell number"></div>
                        <div className="table-cell song">
                            Song
                        </div>
                        <div className="table-cell artist">
                            Artist
                        </div>
                        <div className="table-cell album">
                            Album
                        </div>
                    </div>
                </div>
                <div className="table-body">
                    {tracks.map(RenderRow)}
                </div>
            </div>

        </div>
    )
})

export default ListTable;