import React, {useCallback, useEffect, useMemo, useState} from "react";
import '@styles/_listingItem.scss';
import {useNavigate} from "react-router-dom";
import ItemControls from "@components/player/itemControls";
import Api from "../utils/api";

interface ReleaseItemProps {
    title: string;
    image?: string;
    albumId: string;
    releaseDate?: string;
}

const NewReleaseItem: React.FC<ReleaseItemProps> = (props) => {
    const {title, image, albumId, releaseDate} = props;
    const [loaded, setLoaded] = useState(false);

    let navigate = useNavigate();

    function albumLink() {
        navigate('/album/' + albumId)
    }

    const dateYear = useMemo(() => {
        // If there is no release date, return an empty string.
        if (!releaseDate) return '';
        // Otherwise, get the year from the Date object.
        const date = new Date(releaseDate);
        return date.getFullYear();
    }, [releaseDate]);

// Context Menu
    const [clicked, setClicked] = useState(false);
    const [points] = useState({
        x: 0,
        y: 0,
    });

    //Hide Context Menu on Click Off
    useEffect(() => {
        const handleClick = () => setClicked(false);
        window.addEventListener("click", handleClick)
        return () => {
            window.removeEventListener("click", handleClick);
        };
    })

    //Context Menu Location
    // const contextMenu = (e: any) => {
    //     e.preventDefault();
    //     const rowClicked = e.currentTarget.id;
    //     const activeRow = document.getElementById(rowClicked) as HTMLElement | null;
    //
    //     setClicked(true);
    //
    //     if (activeRow !== null) {
    //         setPoints({
    //             x: e.pageX - activeRow.offsetLeft - 10,
    //             y: e.pageY - activeRow.offsetTop,
    //         });
    //     }
    // }

    //Add to queue button click
    const addToQueue = useCallback(async () => {
        let itemList = await Api.getAlbumId(albumId);

        if (itemList !== undefined) {
            if (itemList.data) {
                // for (let t of itemList.data.tracks) {
                //     // BasePlayer.getQueue().addToQueue(t);
                // }
            }
        }

    }, [albumId])

    return (
        <div className={`listingitem new ${clicked ? 'hasContextMenu' : ''}`}
             id={albumId}
            // onContextMenu={contextMenu}
             onMouseLeave={() => setClicked(false)}>
            <div className="inner">
                {/* Remove Background Animation on Load */}
                <div className={`artwork ${loaded ? 'loaded' : ''}`}>
                    <img src={image} alt={title} onLoad={() => setLoaded(true)}/>

                    <div className="overlay px-2">
                        <ItemControls itemId={albumId} itemType='album'/>
                        <button onClick={albumLink}>
                            <i className="bi bi-list"></i>
                        </button>
                    </div>
                    {clicked && (
                        <div
                            className='e-contextmenu'
                            style={{
                                'top': points.y,
                                'left': points.x,
                            }}
                        >
                            <button onClick={addToQueue}>
                                Add to queue
                            </button>
                        </div>
                    )}
                </div>
                <div className="text">
                    <h2 onClick={albumLink} title={title}>{title}</h2>
                    <h3>{dateYear}</h3>
                </div>
            </div>
        </div>
    )
}

export default NewReleaseItem;