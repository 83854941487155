import axios from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
    IAppleTokenResponse,
    IMeResponse,
    IPublicAlbumsResponse,
    IPublicPlaylistsResponse,
    IPublicSingleAlbumResponse,
    IPublicSinglePlaylistResponse,
    ISpotifyPlaybackStateResponse,
    IStandardBooleanResponse
} from '../interfaces/ApiResponses';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * API calls
 */

class Api {
    /**
     * CORE API CALLS
     */

    // Make a GET request.
    private static async get(url: string): Promise<any> {
        try {
            const res = await axios.get(url, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Make a POST request.
    private static async post(url: string, data: any): Promise<any> {
        try {
            const res = await axios.post(url, data, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Make a PUT request.
    private static async put(url: string, data: any): Promise<any> {
        try {
            const res = await axios.put(url, data, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Make a DELETE request.
    private static async delete(url: string): Promise<any> {
        try {
            const res = await axios.delete(url, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Get the auth status of the user. If this returns HTTP 400, the user is not logged in.
    public static async me(): Promise<IMeResponse> {
        return await this.get(API_URL + '/oauth/me');
    }

    // Logout the user.
    public static async logout() {
        return await this.get(API_URL + '/oauth/logout');
    }

    // Refresh a Spotify access token.
    public static async refreshSpotifyToken() {
        return await this.get(API_URL + '/oauth/spotify/refresh');
    }

    // Obtain a signed Apple Music developer token.
    public static async getAppleMusicToken(): Promise<IAppleTokenResponse> {
        return await this.get(API_URL + '/oauth/apple/token');
    }

    // Get all playlists
    public static async getPlaylists(): Promise<IPublicPlaylistsResponse> {
        return await this.get(API_URL + '/public/playlists')
    }

    // Get all Albums
    public static async getAlbums(): Promise<IPublicAlbumsResponse> {
        return await this.get(API_URL + '/public/albums')
    }

    // Get Playlist ID
    public static async getPlaylistId(playlistId?: string): Promise<IPublicSinglePlaylistResponse> {
        return await this.get(API_URL + '/public/playlists/' + playlistId)
    }

    // Get Album ID
    public static async getAlbumId(albumId?: string): Promise<IPublicSingleAlbumResponse> {
        return await this.get(API_URL + '/public/albums/' + albumId)
    }


    /**
     * SPOTIFY EXTERNAL CALLS
     */

    /**
     * Play a Spotify URI on Spotify
     * @param body
     * @param deviceId
     * @see https://developer.spotify.com/documentation/web-api/reference/#/operations/start-a-users-playback
     */
    public static async spotifyPlay(body: {
        context_uri?: string,
        uris?: string[],
        offset?: object
    }, deviceId?: string): Promise<IStandardBooleanResponse> {
        return await this.put(API_URL + `/spotify/play`, {...body, deviceId});
    }

    public static async spotifyResume(deviceId?: string): Promise<IStandardBooleanResponse> {
        return await this.put(API_URL + `/spotify/resume`, {deviceId});
    }

    public static async spotifyPause(deviceId?: string): Promise<IStandardBooleanResponse> {
        return await this.put(API_URL + `/spotify/pause`, {deviceId});
    }

    public static async spotifyNext(deviceId?: string): Promise<IStandardBooleanResponse> {
        return await this.put(API_URL + `/spotify/skipToNext`, {deviceId});
    }

    public static async spotifyPrev(deviceId?: string): Promise<IStandardBooleanResponse> {
        return await this.put(API_URL + `/spotify/skipToPrevious`, {deviceId});
    }

    public static async spotifySeek(time?: number, deviceId?: string) {
        return await this.put(API_URL + `/spotify/seek/` + time, {deviceId});
    }

    public static async spotifyTransfer(deviceId: string) {
        return await this.put(API_URL + `/spotify/transfer/` + deviceId, {deviceId});
    }

    /**
     * Get the current playback state from Spotify
     */
    public static spotifyGetPlaybackState(): Promise<ISpotifyPlaybackStateResponse> {
        return this.get(API_URL + `/spotify/playbackState`);
    }

    public static spotifyGetDevices() {
        return this.get(API_URL + `/spotify/devices`);
    }
}

/**
 * React hooks for the API calls, where necessary
 */

// Auth call.
export function useMe() {
    // Cache for 5 minutes
    return useQuery<IMeResponse, Error>('auth', () => Api.me(), {retry: 1, staleTime: 1000 * 60 * 10});
}

// Logout call.
export function useLogout() {
    const queryClient = useQueryClient();

    return useMutation('logout', () => Api.logout(), {
        onSuccess: () => {
            queryClient.invalidateQueries('auth');
        }
    });
}

// Spotify refresh call.
export function useSpotifyRefresh() {
    const queryClient = useQueryClient();

    return useMutation('spotifyRefresh', () => Api.refreshSpotifyToken(), {
        onSuccess: () => {
            queryClient.invalidateQueries('auth');
        }
    });
}

// Apple token call.
export function useAppleToken() {
    // Cache for 24 hours
    return useQuery<IAppleTokenResponse, Error>('appleToken', () => Api.getAppleMusicToken(), {
        retry: 1,
        staleTime: 1000 * 60 * 60 * 24
    });
}

// Public playlists
export function usePlaylists() {
    // Cache for 5 minutes
    return useQuery<IPublicPlaylistsResponse, Error>('playlists', () => Api.getPlaylists(), {
        retry: 1,
        staleTime: 1000 * 60 * 5
    });
}

// Public albums
export function useAlbums() {
    // Cache for 5 minutes
    return useQuery<IPublicAlbumsResponse, Error>('albums', () => Api.getAlbums(), {
        retry: 1,
        staleTime: 1000 * 60 * 5
    });
}


export default Api;