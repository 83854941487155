import React, {useCallback, useEffect, useState} from "react";
import '@styles/_listingItem.scss'
import {useNavigate} from "react-router-dom";
import ItemControls from "@components/player/itemControls";
import Api from "../utils/api";

interface GenreItemProps {
    name: string;
    image?: string;
    playlistId: string;
}

const GenreItem: React.FC<GenreItemProps> = (props) => {
    const {name, image, playlistId} = props;
    const [loaded, setLoaded] = useState(false);

    let navigate = useNavigate();

    function playlistLink() {
        navigate('/playlist/' + playlistId)
    }

    // Context Menu
    const [clicked, setClicked] = useState(false);
    const [points] = useState({
        x: 0,
        y: 0,
    });

    //Hide Context Menu on Click Off
    useEffect(() => {
        const handleClick = () => setClicked(false);
        window.addEventListener("click", handleClick)
        return () => {
            window.removeEventListener("click", handleClick);
        };
    })

    //Context Menu Location
    // const contextMenu = (e: any) => {
    //     e.preventDefault();
    //     const rowClicked = e.currentTarget.id;
    //     const activeRow = document.getElementById(rowClicked) as HTMLElement | null;
    //
    //     setClicked(true);
    //
    //     if (activeRow !== null) {
    //         setPoints({
    //             x: e.pageX - activeRow.offsetLeft - 10,
    //             y: e.pageY - activeRow.offsetTop,
    //         });
    //     }
    // }

    //Add to queue button click
    const addToQueue = useCallback(async () => {
        const itemList = await Api.getPlaylistId(playlistId);
        if (itemList !== undefined) {
            if (itemList.data) {
                // for (let t of itemList.data.tracks) {
                //     // BasePlayer.getQueue().addToQueue(t)
                // }
            }
        }
    }, [playlistId]);

    return (
        <div
            className={`listingitem ${clicked ? 'hasContextMenu' : ''}`}
            id={playlistId}
            // onContextMenu={contextMenu}
            onMouseLeave={() => setClicked(false)}>
            <div className="inner">
                {/* Remove Background Animation on Load */}
                <div className={`artwork ${loaded ? 'loaded' : ''}`}>
                    <img src={image} alt={name} onLoad={() => setLoaded(true)}/>
                    <div className="overlay px-2">
                        <ItemControls itemId={playlistId} itemType='playlist'/>
                        <button onClick={playlistLink} className="bi bi-list"/>
                    </div>

                    {clicked && (
                        <div
                            className='e-contextmenu'
                            style={{
                                'top': points.y,
                                'left': points.x,
                            }}
                        >
                            <button onClick={addToQueue}>
                                Add to queue
                            </button>
                        </div>
                    )}
                </div>


            </div>
        </div>
    )
}

export default GenreItem;