import {makeAutoObservable} from 'mobx';
import Api from '../utils/api';

// Shared playback state between players
export interface BasePlayerPlaybackState {
    currentTrack: {
        name: string,
        artist: string,
        duration: number,
        uniqueId: string,
    },
    position: number,
    paused: boolean,
    shuffle: boolean
}

export interface BasePlayerDeviceList {
    devices: {
        name: string,
        id: string,
        active: boolean,
        restricted: boolean
    }[]
}

let currentTime: any;

class BasePlayer {

    // Singleton
    private static instance: BasePlayer;

    // Other state
    public playbackState: BasePlayerPlaybackState = {
        currentTrack: {
            name: '',
            artist: '',
            duration: 0,
            uniqueId: ''
        },
        position: 0,
        paused: true,
        shuffle: false
    };

    public isInteractionBlocked = false;

    public deviceList: BasePlayerDeviceList = {
        devices: []
    }

    private playbackStateInterval: ReturnType<typeof setInterval> | null = null;
    private playheadStateInterval: ReturnType<typeof setInterval> | null = null;

    constructor() {
        // Initialise this class with MobX to create observables and setters etc
        makeAutoObservable(this);
    }

    public static get(): BasePlayer {
        if (!this.instance) this.instance = new BasePlayer();
        return this.instance;
    }

    public updatePlaybackState(state: BasePlayerPlaybackState) {
        this.playbackState = state;
    }

    public updateIsInteractionBlocked(blocked: boolean) {
        this.isInteractionBlocked = blocked;
    }

    public async startPlaybackStateTask() {
        // Stop a task if there's already one running
        await this.stopPlaybackStateTask();

        // Run the task once now
        await this._updatePlaybackStateTask();
        await this._playheadUpdate();

        // Set the new task to poll for the playback state
        this.playbackStateInterval = setInterval(() => {
            this._updatePlaybackStateTask();
        }, 1000 * 5);

        this.playbackStateInterval = setInterval(() => {
            this._playheadUpdate();
        }, 1000);
    }


    public async stopPlaybackStateTask() {
        if (this.playbackStateInterval) {
            clearInterval(this.playbackStateInterval);
            this.playbackStateInterval = null;
        }

        if (this.playheadStateInterval) {
            clearInterval(this.playheadStateInterval);
            this.playbackStateInterval = null;
        }
    }

    public async togglePlay() {
        this.updateIsInteractionBlocked(true);
        if (this.playbackState.paused) {
            await Api.spotifyResume();
            await this.startPlaybackStateTask();
        } else {
            await Api.spotifyPause();
            await this.stopPlaybackStateTask();
            await this._updatePlaybackStateTask();
        }
        this.updateIsInteractionBlocked(false);
    }

    //Skip Track
    public async skipTrack() {
        this.updateIsInteractionBlocked(true);
        await Api.spotifyNext();
        this.updateIsInteractionBlocked(false);
        await this.startPlaybackStateTask();
    }

    //Previous Track
    public async prevTrack() {
        this.updateIsInteractionBlocked(true);
        await Api.spotifyPrev();
        this.updateIsInteractionBlocked(false);
        await this._updatePlaybackStateTask();
    }

    public async transferDevice(deviceId: any) {
        this.updateIsInteractionBlocked(true);
        await Api.spotifyTransfer(deviceId)
        this.updateIsInteractionBlocked(false);
    }

    public async seekTime(seekTime: number) {
        this.updateIsInteractionBlocked(true);
        await Api.spotifySeek(seekTime)
        await this._updatePlaybackStateTask();
        this.updateIsInteractionBlocked(false);

    }

    public async devices() {
        const deviceList = [];

        const devices = await Api.spotifyGetDevices();
        for (const i of devices.data) {
            deviceList.push({
                name: i.name,
                id: i.id,
                active: i.is_active,
                restricted: i.is_restricted
            });
        }

        this.deviceList = {
            devices: deviceList.sort((a, b) => a.name.localeCompare(b.name))
        }
    }

    /**
     * Used internally to fetch the current playback state from Spotify
     * @private
     */
    private async _playheadUpdate() {
        currentTime = currentTime + 0.4;
        document.documentElement.style.setProperty(
            '--currentPosition',
            currentTime + '%'
        );
    }

    private async _updatePlaybackStateTask() {
        const result = await Api.spotifyGetPlaybackState();
        if (result.data?.currently_playing_type !== 'track') {
            // Not currently playing a track, so ignore the current playback state
            return;
        }

        const track = result.data?.item as SpotifyApi.TrackObjectFull | null | undefined;

        if (track?.duration_ms && result?.data?.progress_ms) {
            currentTime = (result?.data?.progress_ms / track?.duration_ms) * 100;
        }

        this.updatePlaybackState({
            currentTrack: {
                name: track?.name || '',
                artist: (track?.artists && track.artists.length > 0) ? track?.artists[0].name : '',
                duration: track?.duration_ms || 0,
                uniqueId: track?.id || ''
            },
            position: result?.data?.progress_ms || 0,
            paused: (result?.data?.is_playing !== undefined) ? !result?.data?.is_playing : false,
            shuffle: result?.data?.shuffle_state || false
        })
    }
}

const player = BasePlayer.get();
export default player;