import {Container} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import NewReleaseItem from '@components/NewReleaseItem';
import React, {useCallback} from 'react';
import {useAlbums} from "../utils/api";

const NewReleases = () => {
  const {status, data} = useAlbums();

  const renderNewReleases = useCallback(() => {
      const albums: JSX.Element[] = [];

      if (data && data.data) {
          for (const i of data.data) {
              albums.push(<NewReleaseItem key={i.id} title={i.title} image={i.image} albumId={i.id} releaseDate={i.releaseDate}/>);
          }
      }

      return albums;
  }, [data]);

  return (
    <>
      <Helmet>
        <title>New Releases | StreamBeats.app</title>
      </Helmet>
      <Container fluid className={'py-4 py-lg-5 listings'}>
        <h1 className='mb-4 mt-0'>New Releases</h1>
        {
          status === 'loading' ? <div className={'spinner-border text-primary'} /> : (
            <div className="row">
              {/*Output latest 20 albums*/}
              {renderNewReleases()}
            </div>
          )
        }
      </Container>
    </>

  )
}

export default NewReleases;